import React from 'react';
import './Hero.css';
import Sketch9 from './9';
import { motion } from 'framer-motion';

const Hero = ({ switchContent }) => {
    const handleViewGallery = () => {
        switchContent('gallery');
    };

    return (
        <motion.section
            className="hero"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <Sketch9 />
            <div className="hero__content">
                <h1 className="hero__title">A home for inscribed art.</h1>
                <button className="hero__cta" onClick={handleViewGallery}>
                    Gallery <span className="hero__cta-arrow">→</span>
                </button>
            </div>
            <div className='hero__info'>
                <h2 className="hero__subheading">Moonhug #9</h2>
                <p className="hero__description">Watusi</p>
            </div>
        </motion.section>
    );
};

export default Hero;
