import React, { useEffect, useState } from 'react';
import './Featured.css';
import { motion } from 'framer-motion';

const Featured = ({ switchContent }) => {
    const [artworks, setArtworks] = useState([
        'fabric.png'
    ]);
    const featuredArtistId = '4'; 

    const handleViewGallery = () => {
        switchContent('gallery', featuredArtistId);
    };

    const textVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
    };

    const imageVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <>
            <motion.section
                className="featured"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="featured__content">
                    <motion.div
                        className="featured__details"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 1, delay: 0.5 }}
                        variants={textVariants}
                    >
                        <h1>Artist Spotlight</h1>
                        <div className="featured__artist">
                            <p href="#" className="featured__artist-name">litterthanlit</p>
                        </div>
                    </motion.div>
                </div>
            </motion.section>
            <motion.section
                className="spotlight"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <motion.h2
                    className="spotlight__title"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: 1 }}
                    variants={textVariants}
                >
                    "Colors are a universal language, a lens we see the world through...a window"
                </motion.h2>
                <div className="spotlight__content">
                    <motion.div
                        className="spotlight__text"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 1, delay: 1.5 }}
                        variants={textVariants}
                        >
                        <p>
                        I use them in my pieces to speak whats on my mind how I'm perceiving the world at that time. 

                        Play is the other big element. Through play I'm able to get in a state where I flow. 
                        MORE PLAY
                        </p>
                        <button className="hero__cta" onClick={handleViewGallery}>
                            View Gallery <span className="hero__cta-arrow">→</span>
                        </button>
                    </motion.div>
                    <div className="spotlight__extra">
                        <div className="spotlight__grid">
                            {artworks.map((filename, index) => (
                                <motion.div
                                    className="spotlight__image-wrapper"
                                    key={index}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    transition={{ duration: 1, delay: 0.2 * index }}
                                    variants={imageVariants}
                                >
                                    <img
                                        src={`/artworks/${filename}`}
                                        alt={`Artwork ${index + 1}`}
                                        className="spotlight__image"
                                        onError={(e) => {
                                            console.error(`Failed to load image: ${e.target.src}`);
                                        }}
                                    />
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </motion.section>
        </>
    );
};

export default Featured;
