import React from 'react';
import './AboutPopup.css';

const AboutPopup = ({ onClose }) => {
    return (
        <div className="about-popup-overlay">
            <div className="about-popup">
                <button className="about-popup-close" onClick={onClose}>×</button>
                <h2>About Us</h2>
                <p>At Immuta, our mission is to illuminate the talents of deserving artists, bringing their exceptional works into the spotlight. We specialize in curating high-value fine art inscribed on-chain, ensuring both authenticity and permanence. Our exclusive council, functioning through a decentralized organization, meticulously selects each piece to uphold the highest standards of excellence.
                    <br></br>
                    <br></br>
                    We proudly promote these masterpieces to a discerning and sophisticated clientele, ensuring that art of true value reaches those who appreciate it most.</p>
                <br></br>
                <br></br>
                <p>A product of <a href='https://blank-bay.vercel.app' target='_blank'>Blank.</a></p>
            </div>
        </div>
    );
};

export default AboutPopup;
