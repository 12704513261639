import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Header.css';
import { motion } from 'framer-motion';

const Header = ({ switchContent }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleNavigation = (content) => {
        if (content !== 'exhibit') {
            switchContent(content);
            setIsMenuOpen(false);
        }
    };

    return (
        <>
            <header className="header">
                <nav className="nav">
                    <Link to="/" onClick={() => handleNavigation('featured')}>
                        <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" className="nav__logo" />
                    </Link>
                    <div className="nav__toggle" onClick={toggleMenu}>
                        {isMenuOpen ? <FaTimes /> : <FaBars />}
                    </div>
                </nav>
            </header>
            {isMenuOpen && (
                <motion.div
                    className="fullscreen-menu"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <ul className="fullscreen-menu__items">
                        <li className="fullscreen-menu__item" onClick={() => handleNavigation('featured')}>HOME</li>
                        <li className="fullscreen-menu__item" onClick={() => handleNavigation('gallery')}>
                            COLLECT
                        </li>
                        <li className="fullscreen-menu__item fullscreen-menu__item--disabled">
                            EXHIBIT
                        </li>
                        <li className="fullscreen-menu__item" onClick={() => handleNavigation('about')}>ABOUT</li>
                    </ul>
                </motion.div>
            )}
        </>
    );
};

export default Header;
